import React, { useState, useEffect } from "react";
import LogoIcon from "./Imagens/Ebitech3-logo.png";
import RelIcon from "./Imagens/Rel.png";
import EquIcon from "./Imagens/Equi.png";
import CalendarIcon from "./Imagens/calendar.png";
import AnaliseIcon from "./Imagens/analise.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import TabelaRelatorios from "./TabelaRelatorios";
import AnaliseRelatorios from "./AnaliseRelatorios";

const Relatorios = ({ usuario }) => {
  const [filtro, setFiltro] = useState([]);
  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");
  const [equipamentos, setEquipamentos] = useState([]);
  const [equipamentoSelecionado, setEquipamentoSelecionado] = useState("");
  const [nomeEquipamentoSelecionado, setNomeEquipamentoSelecionado] = useState("");
  const [analise, setAnalise] = useState(null);
  const [visualizacao, setVisualizacao] = useState("relatorio");
  const [mudancasFpn, setMudancasFpn] = useState([]);

  // const ec2Url = process.env.NODE_ENV === 'development'
  //   ? process.env.REACT_APP_API_URL_DEV
  //   : process.env.REACT_APP_API_URL_PROD;

  useEffect(() => {
    // fetch(`http://3.12.177.212/equipamentos.json`)
    // fetch(`${ec2Url}/equipamentos.json`)
    // fetch(`http://localhost:8080/equipamentos.json`)
    fetch(`https://ec2.ebitech.com.br/equipamentos_ebitech.json`)
      .then((response) => response.json())
      .then((data) => {
        const equipamentosAutorizados = data.filter((equip) =>
          equip.users.includes(usuario.username)
        );
        setEquipamentos(equipamentosAutorizados);
      })
      .catch((error) =>
        console.error("Erro ao carregar os equipamentos:", error)
      );
    }, [usuario]);
  // }, [usuario, ec2Url]);

  const containerStyle = {
    padding: "20px",
    maxWidth: "flex",
    margin: "0 auto 0 200px",
  };

  const formStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  };

  const labelStyle = {
    fontWeight: "bold",
  };

  const inputStyle = {
    width: "100%",
    padding: "8px",
  };

  const dateInputContainerStyle = {
    display: "flex",
    gap: "10px",
    flexDirection: "column",
    textAlign: "center",
  };

  const iconStyle = {
    marginRight: "10px",
    marginLeft: "10px",
    width: "25px",
    height: "25px",
  };

  const iconButtonStyle = {
    marginRight: "10px",
    width: "25px",
    height: "25px",
  };

  const lerDadosCSV = async (ns) => {
    if (!ns) return;

    ns = ns.trim().replace("- ", "");
    const arq = ns.replace("/", "-");
    // const url = `http://3.12.177.212/api/relatorios/logs_${arq}.csv`;
    // const url = `${ec2Url}/api/relatorios/logs_${arq}.csv`;
    const url = `https://ec2.ebitech.com.br/api/relatorios/logs_${arq}.csv`;

    const formatarDataBR = (data) => {
      const [ano, mes, dia] = data.split('-');
      return `${dia}-${mes}-${ano}`;
    };
    
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          `Falha ao carregar o arquivo CSV: Status ${response.status}`
        );
      }
      const text = await response.text();
      const linhas = text.trim().split("\n");
      let valorAnteriorFpn = null;
      const mudancasDetectadas = [];

      const dados = linhas.map((linha) => {
        try {
          const dado = JSON.parse(linha);
          
          // Verifica mudanças no campo "fpn"
          if (valorAnteriorFpn !== null && valorAnteriorFpn !== dado.fpn) {
            mudancasDetectadas.push({
              data: formatarDataBR(dado.data),
              hora: dado.hora,
              //valorAnterior: valorAnteriorFpn ? "Ligado" : "Desligado",
              valorAtual: dado.fpn ? "Ligado" : "Desligado",
              bateria: dado.bv !== undefined ? dado.bv : "N/A",
            });
          }
          valorAnteriorFpn = dado.fpn; // Atualiza o valor anterior do campo "fpn"

          return dado;
        } catch (error) {
          console.error("Erro ao parsear linha:", linha, error);
          return null;
        }
      }).filter(Boolean);

      setMudancasFpn(mudancasDetectadas);

      const dadosFiltrados = dados.filter((dado) => {
        const dataDado = dado.date ? new Date(dado.date) : new Date(`${dado.data}T${dado.hora}`);
        const dataInicio = new Date(dataInicial + 'T00:00:00');
        const dataFim = new Date(dataFinal + 'T23:59:59');
        return dataDado >= dataInicio && dataDado <= dataFim;
      });

      setFiltro(dadosFiltrados);
      realizarAnalise(dadosFiltrados, dataInicial, dataFinal);
    } catch (error) {
      console.error("Erro ao ler o arquivo CSV:", error);
    }
  };

  const realizarAnalise = (dados, dataInicial, dataFinal) => {
    if (dados.length === 0) return;

    const formatarData = (data) => {
      const d = new Date(data);
      return d.toISOString().split('T')[0];
    };

    const dadosPorDia = dados.reduce((acc, dado) => {
      const dataDado = formatarData(dado.date ? dado.date : `${dado.data}T${dado.hora}`);
      if (!acc[dataDado]) {
        acc[dataDado] = [];
      }
      acc[dataDado].push(dado);
      return acc;
    }, {});

    const intervalosAnalisados = [];

    for (const dia in dadosPorDia) {
      const inicioDia = new Date(`${dia}T06:00:00`);
      const fimDia = new Date(`${dia}T11:30:00`);
      const dadosDia = dadosPorDia[dia];

      const intervalos = [];
      const intervaloEmMinutos = 30;
      let i = new Date(inicioDia);

      while (i < fimDia) {
        const inicio = new Date(i);
        const fimIntervalo = new Date(i.getTime() + intervaloEmMinutos * 60000);

        const dadosIntervalo = dadosDia.filter((dado) => {
          const dataDado = new Date(dado.date ? dado.date : `${dado.data}T${dado.hora}`);
          return dataDado >= inicio && dataDado < fimIntervalo;
        });

        if (dadosIntervalo.length > 0) {
          const tensoes = dadosIntervalo.map((dado) => dado.bv);
          const menorTensao = Math.min(...tensoes);
          const maiorTensao = Math.max(...tensoes);
          const diferencaTensao = (maiorTensao - menorTensao).toFixed(2);

          intervalos.push({
            inicio: inicio,
            fim: fimIntervalo,
            diferencaTensao,
            menorTensao,
            maiorTensao,
          });
        }

        i = fimIntervalo;
      }

      if (intervalos.length > 0) {
        intervalosAnalisados.push({ dia, intervalos });
      }
    }

    setAnalise(intervalosAnalisados);
  };

  const handleRelatorio = (e) => {
    e.preventDefault();
    setVisualizacao("relatorio");
    if (equipamentoSelecionado) {
      lerDadosCSV(equipamentoSelecionado);
      const equipamento = equipamentos.find((equip) => equip.ns === equipamentoSelecionado);
      setNomeEquipamentoSelecionado(equipamento ? equipamento.nome : "");
    }
  };

  const handleAnalise = (e) => {
    e.preventDefault();
    setVisualizacao("analise");
    if (equipamentoSelecionado) {
      lerDadosCSV(equipamentoSelecionado);
      const equipamento = equipamentos.find((equip) => equip.ns === equipamentoSelecionado);
      setNomeEquipamentoSelecionado(equipamento ? equipamento.nome : "");
    }
  };

  return (
    <div style={containerStyle}>
      <img
        src={LogoIcon}
        alt="Logo"
        style={{
          position: "absolute",
          top: 20,
          left: 5,
          width: "144px",
          height: "40px",
        }}
      />
      <h2 style={{ textAlign: "center" }}>Relatórios</h2>
      <form style={formStyle}>
        <div className="row m-t">
          <div className="col-4">
            <div style={dateInputContainerStyle}>
              <label style={labelStyle}><img src={EquIcon} style={iconStyle} alt="Equipamentos" />Equipamento</label>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <select
                  value={equipamentoSelecionado}
                  onChange={(e) => setEquipamentoSelecionado(e.target.value)}
                  style={inputStyle}
                >
                  <option value="">Selecione um equipamento</option>
                  {equipamentos.map((equipamento) => (
                    <option key={equipamento.id} value={equipamento.ns}>
                      {equipamento.ns} - {equipamento.nome}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div style={dateInputContainerStyle}>
              <label style={labelStyle}><img src={CalendarIcon} style={{ marginRight: "10px", marginLeft: "10px", width: "22px", height: "22px",}} alt="Calendar" />Período</label>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <input
                  type="date"
                  value={dataInicial}
                  onChange={(e) => setDataInicial(e.target.value)}
                  style={inputStyle}
                />
                <span>até</span>
                <input
                  type="date"
                  value={dataFinal}
                  onChange={(e) => setDataFinal(e.target.value)}
                  style={inputStyle}
                />
              </div>
            </div>
          </div>
          <div className="col-4">
            <button style={{ marginTop: "40px", marginRight: "10px" }} onClick={handleRelatorio}><img src={RelIcon} style={iconButtonStyle} alt="Relatorio Icon"/>
              Relatório
            </button>
            <button style={{ marginTop: "40px" }} onClick={handleAnalise}><img src={AnaliseIcon} style={iconButtonStyle} alt="Analise Icon"/>
              Análise
            </button>
          </div>
        </div>
      </form>
      {nomeEquipamentoSelecionado && (
        <h3>Equipamento: {nomeEquipamentoSelecionado}</h3>
      )}
      {visualizacao === "relatorio" && filtro.length > 0 && (
        <TabelaRelatorios dados={filtro} nomeEquipamento={nomeEquipamentoSelecionado} dataInicial={dataInicial} dataFinal={dataFinal} />
      )}
      {visualizacao === "analise" && analise && (
        <AnaliseRelatorios analise={analise} />
      )}
      {mudancasFpn.length > 0 && (
        <div style={{ marginTop: "20px" }}>
          <h3 style={{ marginBottom: "10px", textAlign: "center" }}>Acionamento da Luminária</h3>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>Data</th>
                <th style={{ textAlign: "center" }}>Hora</th>
                <th style={{ textAlign: "center" }}>Status</th>
                <th style={{ textAlign: "center" }}>Bateria</th>
              </tr>
            </thead>
            <tbody>
              {mudancasFpn.map((mudanca, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center" }}>{mudanca.data}</td>
                  <td style={{ textAlign: "center" }}>{mudanca.hora}</td>
                  <td style={{ textAlign: "center" }}>{mudanca.valorAtual}</td>
                  <td style={{ textAlign: "center" }}>{mudanca.bateria}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Relatorios;
